import { createWebHistory, createRouter } from 'vue-router';
// import store from '../vuex/store';
import Home from '@/view/dashboard/Home.vue';

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    // component: () => import(/* webpackChunkName: "feed" */ '@/view/dashboard/LiveFeed.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL
  ),
  linkExactActiveClass: 'active',
  routes,
});

// router.beforeEach((to, from, next) => {
//   // console.log(to, store);
//   // if (to.meta.auth && store.state.auth.login) {
//   //   next({ to: '/' });
//   // } else if (!to.meta.auth && !store.state.auth.login) {
//   //   next({ name: 'login' });
//   // } else {
//   //   next();
//   // }
//   if (to.path === '/feed') {
//     next();
//   }
//   // Virtual logout route -> would be redirect to login anyway - no need to interrupt
//   console.log(to.path);
//   if (to.path === '/auth/logout') {
//     store.dispatch('account/logout').then(() => {
//       next({ name: 'login' });
//     });
//   } else if (to.path === '/auth/login' && to.redirectedFrom === '/auth/logout') {
//     store.dispatch('account/logout').then(() => {
//       next();
//     });
//   } else if (to.meta.auth) {
//     // Do simple check with cookie
//     store
//       .dispatch('account/getUsageStats')
//       .then(() => {})
//       .catch(() => {});
//     if (store.getters['account/hasUserInfo']) {
//       next();
//     } else {
//       next({ name: 'login' });
//     }
//   } else {
//     next();
//   }
// });

export default router;
