<template>
  <div class="home">
    <a-tabs type="card" default-active-key="1" v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="Live Feed">
        <LiveFeed />
      </a-tab-pane>
      <a-tab-pane key="2" tab="Tag Cloud">
        <WordCloud />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { ref } from 'vue';
import LiveFeed from '@/view/dashboard/LiveFeed.vue';
import WordCloud from '@/view/dashboard/WordCloud.vue';

export default {
  components: {
    LiveFeed,
    WordCloud,
  },
  setup() {
    const activeKey = ref('1');
    return {
      activeKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
</style>
