import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import themeLayout from './modules/themeLayout/actionCreator';
import headerSearchData from './modules/headerSearch/actionCreator';
import auth from './modules/authentication/axios/actionCreator';

import account from './modules/account';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'domv3',
  reducer: (state) => ({ account: state.account }),
});

export default createStore({
  modules: {
    themeLayout,
    headerSearchData,
    auth,
    account,
  },
  plugins: [vuexLocal.plugin],
});
