<template>
  <div class="sentiment-circle" :class="sentimentClass">
    <sdFeatherIcons :type="sentimentIcon" size="28" />
  </div>
</template>

<script>
export default {
  name: 'SentimentCircleIcon',
  props: {
    data: Object,
  },
  computed: {
    sentimentIcon() {
      if (this.data && this.data.analytic) {
        if (this.data.analytic.sentiment_score > 1) return 'smile';
        if (this.data.analytic.sentiment_score < -1) return 'frown';
        return 'meh';
      }
      return '';
    },
    sentimentClass() {
      if (this.data && this.data.analytic) {
        if (this.data.analytic.sentiment_score > 1) return 'positive-bg';
        if (this.data.analytic.sentiment_score < -1) return 'negative-bg';
        return 'neutral-bg';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.sentiment-circle {
  height: 48px;
  width: 48px;
  border-radius: 48px;
  padding: 10px;
  &.positive-bg {
    background-color: rgba(32, 201, 151, 0.15);
    color: #20c997;
  }
  &.neutral-bg {
    background-color: rgba(250, 139, 12, 0.15);
    color: #fa8b0c;
  }
  &.negative-bg {
    background-color: rgba(255, 77, 79, 0.15);
    color: #ff4d4f;
  }
}
</style>
