<template>
  <div class="word-cloud">
    <canvas id="wc" width="1024" height="768"></canvas>
  </div>
</template>

<script>
// import $ from 'jquery';
import { onMounted } from 'vue';
import WordCloud from 'wordcloud';
// const jsonData = require('../../WordCountData/Startup Txt_THAI_SITE 2021_wordcount.json');
import api from '@/services/api';

let maxVal = 10;
// let maxSize = 400;
// 20 - 30
let minSize = 30;

const options = {
  gridSize: 10,
  // gridSize: Math.round((16 * $('#wc').width()) / 1024),
  weightFactor: function (size) {
    const p = Math.pow(size / maxVal + 1, 2) * minSize;
    // const p = Math.pow(size / maxVal + 1, 2) + minSize;

    // console.log(p, size);

    // let val = (Math.pow(size, 2.25) * $('#wc').width()) / 128;
    // console.log(size, val);
    // if (val < 50) val += 30;
    // return val;
    return p;
  },
  // fontFamily: 'DM Sans, Times, sans-serif',
  rotateRatio: 0.5,
  rotationSteps: 2,
  backgroundColor: '#fafafa',
};
export default {
  setup() {
    const init = async () => {
      // find max
      // let max = 0;
      // const wordData = [];
      // jsonData.forEach((o) => {
      //   wordData.push([o.word, o.count]);
      //   if (o.count > maxVal) maxVal = o.count;
      // });
      // setTimeout(() => {
      //   WordCloud(document.getElementById('wc'), {
      //     list: wordData,
      //     ...options,
      //   });
      // }, 2000);

      const result = await api.getWordCloud().catch(() => {});
      if (result && result.word_list) {
        const stat = result.stat;
        maxVal = stat.max;
        const arr = result.word_list.map((x) => [x.word, x.count]);
        setTimeout(() => {
          WordCloud(document.getElementById('wc'), {
            list: arr,
            ...options,
          });
        }, 2000);
      }
    };
    onMounted(() => {
      init();
    });
    // init();
    return {};
  },
};
</script>

<style lang="scss" scoped>
.word-cloud {
  background: #fafafa;
  height: calc(100vh - 40px);
}
#wc {
  width: 100%;
  height: auto;
}
</style>
