<template>
  <div class="message-head">
    <div class="left-block">
      <div class="profile-img-wrapper">
        <a href="#" class="profile-img">
          <img class="user-img" v-if="newProfileImage" :src="newProfileImage" />
        </a>
        <div class="count-badge" v-if="orderNo && orderNo > 0">
          {{ orderNo }}
        </div>
      </div>
      <div class="left-title">
        <div class="username-row">
          {{ username }}
        </div>
        <div class="source-row">
          <span class="source-tag" :class="source">
            <span class="logo">
              <span class="facebook" v-if="source === 'facebook'">
                <font-awesome-icon :icon="faFacebookF" />
              </span>
              <span class="twitter" v-else-if="source === 'twitter'">
                <font-awesome-icon :icon="faTwitter" />
              </span>
              <span class="instagram" v-else-if="source === 'instagram'">
                <font-awesome-icon :icon="faInstagram" />
              </span>
              <span class="youtube" v-else-if="source === 'youtube'">
                <font-awesome-icon :icon="faYoutube" />
              </span>
              <span class="pantip" v-else-if="source === 'pantip'">
                <sdFeatherIcons type="message-square" size="9" />
              </span>
              <span class="website" v-else>
                <sdFeatherIcons type="globe" size="8" />
              </span>
            </span>
            {{ source }}
          </span>
          <span class="date-tag" v-if="date"> | Posted on {{ date }} </span>
        </div>
      </div>
    </div>
    <div class="right-block">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import { faFacebookF, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import defaultPlaceholder from '@/static/img/placeholder_gray.png';

export default {
  props: {
    username: String,
    source: String,
    date: String,
    profileImage: String,
    orderNo: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { profileImage, source } = toRefs(props);

    const getCDNImageUrl = (source, url) => {
      return `https://cdn.insightera.co.th/img/user-force/${source}/${url}`;
    };

    const newProfileImage = computed(() => {
      let oldProfile = profileImage.value;
      if (profileImage.value) {
        if (oldProfile.indexOf('cdn.ask-dom.com')) {
          oldProfile = oldProfile.replace('cdn.ask-dom.com', 'cdn.insightera.co.th');
        }
        // if (source.value === 'facebook') {
        //   return oldProfile;
        // }
        return getCDNImageUrl(source.value, oldProfile);
      }
      return defaultPlaceholder;
    });

    return {
      newProfileImage,
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-block {
    display: flex;
    align-items: center;
    .profile-img-wrapper {
      margin-right: 15px;
    }
    .profile-img {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      overflow: hidden;
      background-color: #ddd;
      border: 1px solid #ddd;
      .user-img {
        width: 40px;
        height: 40px;
      }
    }
    .username-row {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .source-row {
      line-height: 16px;
      .date-tag {
        font-size: 13px;
        color: #9299b8;
        white-space: nowrap;
      }
      .source-tag {
        border-radius: 18px;
        height: 18px;
        line-height: 14px;
        font-size: 10px;
        font-weight: 500;
        text-transform: capitalize;
        padding: 2px 5px 2px 2px;
        white-space: nowrap;
        display: inline-block;
        background-color: rgba(44, 153, 255, 0.15);
        .logo {
          height: 14px;
          width: 14px;
          border-radius: 24px;
          color: #fff;
          margin-right: 4px;
          font-size: 10px;
          line-height: 14px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(44, 153, 255, 1);
        }
        &.facebook {
          background-color: rgba(24, 119, 242, 0.15);
          svg {
            height: 8px;
            width: 8px;
          }
          .logo {
            background-color: rgba(24, 119, 242, 1);
          }
        }
        &.twitter {
          background-color: rgba(34, 164, 240, 0.15);
          svg {
            height: 8px;
            width: 8px;
          }
          .logo {
            background-color: rgb(34, 164, 240);
          }
        }
        &.pantip {
          background-color: rgba(118, 116, 188, 0.15);
          .logo {
            background-color: rgba(118, 116, 188, 1);
          }
        }
        &.youtube {
          background-color: rgba(255, 0, 0, 0.15);
          svg {
            height: 8px;
            width: 8px;
          }
          .logo {
            background-color: #ff0000;
          }
        }
        &.instagram {
          background-color: rgba(228, 64, 95, 0.15);
          svg {
            height: 8px;
            width: 8px;
          }
          .logo {
            background-color: rgba(228, 64, 95, 1);
          }
        }
        &.website {
          background-color: rgba(44, 153, 255, 0.15);
          .logo {
            background-color: rgba(44, 153, 255, 1);
          }
        }
      }
    }
  }
  .profile-img-wrapper {
    position: relative;
    .count-badge {
      position: absolute;
      font-size: 10px;
      border: 2px solid #fff;
      border-radius: 24px;
      background: #097cff;
      bottom: 0;
      right: 0;
      line-height: 12px;
      width: 16px;
      height: 16px;
      color: #fff;
      text-align: center;
      font-weight: 500;
    }
  }
}
</style>
