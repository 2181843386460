<template>
  <div class="category-bar">
    <template v-if="data && data.analytic">
      <span class="category-tag" v-for="(category, level) in categoryData" :key="category.key">
        <span class="category-name"> {{ category.name }}: </span>
        <span class="inner-tag" :class="`level-${level}`" v-for="c in category.data" :key="c">
          {{ c }}
        </span>
      </span>
    </template>
  </div>
</template>

<script>
const catKey = [
  'categories',
  'sub_categories_level_1',
  'sub_categories_level_2',
  'sub_categories_level_3',
  'sub_categories_level_4',
];
export default {
  props: {
    data: Object,
    editable: Boolean,
    subCategoryName: Object,
  },
  computed: {
    categoryData() {
      let result = [];
      if (this.data && this.data.analytic && this.subCategoryName) {
        for (let i in catKey) {
          const k = catKey[i];
          const l = this.data.analytic[k] || [];
          if (l && l.length > 0) {
            // TODO refactor to vue3 setup style component
            const name = this.subCategoryName[k];
            result.push({
              name,
              key: k,
              data: l,
            });
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-bar {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  .category-tag {
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    padding: 6px 8px;
    margin-right: 4px;
    margin-bottom: 8px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .category-name {
      font-size: 13px;
      line-height: 20px;
      margin-right: 4px;
    }
    .inner-tag {
      font-size: 10px;
      line-height: 20px;
      margin-right: 4px;
      padding: 3px 6px;
      border-radius: 4px;
      text-transform: capitalize;
      &.level-0 {
        color: rgba(95, 99, 242, 1);
        background: rgba(95, 99, 242, 0.15);
      }
      &.level-1 {
        color: rgba(91, 202, 244, 1);
        background: rgba(91, 202, 244, 0.15);
      }
      &.level-2 {
        color: rgba(228, 88, 124, 1);
        background: rgba(228, 88, 124, 0.15);
      }
      &.level-3 {
        color: rgba(77, 212, 172, 1);
        background: rgba(77, 212, 172, 0.15);
      }
      &.level-4 {
        color: rgba(255, 189, 89, 1);
        background: rgba(255, 189, 89, 0.15);
      }
    }
  }
}
</style>
