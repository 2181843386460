function inIframe() {
  try {
    if (window.location.href.indexOf('http://localhost:8080') > -1) {
      return true;
    }
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';

import $ from 'jquery';
window.$ = $;

// import VueWordCloud from 'vuewordcloud';

// app.component('VueWordCloud', VueWordCloud);

if (inIframe()) {
  app.config.productionTip = false;
  app.use(store);
  app.use(router);
  app.mount('#app');
} else {
  console.log('Iframe only');
}
