<template>
  <div class="engagement-bar">
    <div class="left-block">
      <div class="total-engage">
        <span class="total-engage-title">Total: </span>
        <span class="total-engage-value">
          {{ engageValue.all }}
        </span>
      </div>
      <div class="engage-value" v-if="data.info.source === 'facebook'">
        <EngagementIcon :type="'reaction_count'" />
        <span>{{ engageValue.reaction }}</span>
      </div>
      <template v-else>
        <div class="engage-value icon" v-if="data && data.summary && data.summary.like_count">
          <sdFeatherIcons type="thumbs-up" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.like_count, '0,0.0a', true) }}</span>
        </div>
        <div class="engage-value icon" v-if="data && data.summary && data.summary.dislike_count">
          <sdFeatherIcons type="thumbs-down" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.dislike_count, '0,0.0a', true) }}</span>
        </div>
      </template>
      <div class="engage-value icon" v-if="data && data.summary && data.summary.comment_count">
        <sdFeatherIcons type="message-square" size="14" class="engage-icon" />
        <span>{{ numeral(data.summary.comment_count, '0,0.0a', true) }}</span>
      </div>
      <div class="engage-value icon" v-if="data && data.summary && data.summary.share_count">
        <sdFeatherIcons type="share-2" size="14" class="engage-icon" />
        <span>{{ numeral(data.summary.share_count, '0,0.0a', true) }}</span>
      </div>
      <div class="engage-value icon" v-if="data.info.source === 'twitter'">
        <sdFeatherIcons type="repeat" size="14" class="engage-icon" />
        <span>{{ numeral(data.summary.retweet_count, '0,0.0a', true) || '0' }}</span>
      </div>
    </div>
    <div class="right-block">
      <div class="engage-value icon" v-if="data && data.summary && data.summary.view_count">
        <sdFeatherIcons type="eye" size="14" class="engage-icon" />
        <span>{{ numeral(data.summary.view_count, '0,0.0a', true) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/services/helper';
import EngagementIcon from './EngagementIcon.vue';

export default {
  name: 'EngagementBar',
  components: {
    EngagementIcon,
  },
  props: {
    data: Object,
  },
  methods: {
    numeral: helper.numeral,
  },
  computed: {
    engageValue() {
      let all = 0;
      let reaction = 0;
      if (this.data && this.data.info) {
        if (this.data.info.source === 'facebook') {
          let allValue = 0;
          let reactionValue = 0;
          let sumCommentShare = 0;
          let keys = Object.keys(this.data.summary);
          for (let summaryKey of keys) {
            if (summaryKey.indexOf('count') > -1) {
              if (summaryKey !== 'view_count' && summaryKey !== 'reaction_count') {
                if (this.data.summary[summaryKey]) {
                  // TODO check number ?
                  allValue += this.data.summary[summaryKey];
                  if (summaryKey === 'comment_count' || summaryKey === 'share_count') {
                    sumCommentShare += this.data.summary[summaryKey];
                  } else {
                    reactionValue += this.data.summary[summaryKey];
                  }
                }
              }
            }
          }
          // If field is more than calculated total - use original field instead
          if (this.data.summary && this.data.summary.reaction_count) {
            if (this.data.summary.reaction_count > reactionValue) {
              reactionValue = this.data.summary.reaction_count;
              allValue = reactionValue + sumCommentShare;
            }
          }
          if (this.data.analytic.engagement_score > allValue) {
            // console.log('SCORE > ALL');
            all = this.data.analytic.engagement_score;
            let showReaction = this.data.analytic.engagement_score - sumCommentShare;
            if (showReaction < 0) showReaction = 0;
            reaction = showReaction;
          } else {
            all = allValue;
            reaction = reactionValue;
          }
        } else {
          all = this.data.analytic.engagement_score;
        }
      }
      return {
        all: helper.numeral(all),
        reaction: helper.numeral(reaction, '0,0.0a', true, 0),
      };
    },
    remainingCounter() {
      let result = [];
      if (this.data && this.data.info) {
        if (this.data.info.source === 'twitter') {
          // Twitter has no more ...
        } else if (this.data.info.source === 'facebook') {
          // Facebook show comment and share
          result = [
            {
              type: 'comment_count',
              value: this.data.summary.comment_count || 0,
            },
            {
              type: 'share_count',
              value: this.data.summary.share_count || 0,
            },
          ];
        } else {
          const summaryKeys = Object.keys(this.data.summary);
          for (let k of summaryKeys) {
            if (k !== 'availableCount' && k !== 'view_count' && k !== 'vote') {
              if (typeof this.data.summary[k] === 'number') {
                result.push({
                  type: k,
                  name: helper.normalizeKey(k),
                  value: this.data.summary[k],
                });
              } else {
                console.warn('Engage not number ..', k);
              }
            }
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.engagement-bar {
  font-size: 13px;
  color: #5a5f7d;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .total-engage {
    background-color: #f8f9fb;
    white-space: nowrap;
    padding: 6px 15px;
    margin-right: 15px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 8px;
    .total-engage-title {
      margin-right: 3px;
    }
    .total-engage-value {
      color: #097cff;
    }
  }
  .engage-value {
    color: #9299b8;
    margin-right: 20px;
    margin-bottom: 8px;
    &.icon {
      display: flex;
      align-items: center;
    }
    .engage-icon {
      margin-right: 6px;
    }
  }
  .counter-wrapper-inner {
    display: flex;
    width: 100%;
  }
  .left-block,
  .right-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
