<template>
  <div id="inner-app" class="live-feed">
    <div class="title-text">Live Feed</div>
    <div class="time-text">Result at {{ currentFeedTime }}</div>
    <div class="page-content">
      <div class="masonry-wrapper">
        <div class="masonry">
          <div
            class="masonry-content"
            :id="`msg-${item.info.id}`"
            v-for="item in feedObj.feedData"
            :key="item.user_name"
          >
            <div class="message-card">
              <MessageItem
                class="list-item"
                :item="item"
                :orderNo="0"
                :subCategoryName="feedObj.subCategoryName"
                :showCategory="true"
                :hasMedia="true"
                :widget="true"
                :gridItem="true"
                :windowWidth="width"
                :updateVar="currentFeedTime"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-show="isInit && hasMore" class="eof">
        <a-spin />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import dayjs from 'dayjs';
import inView from 'in-view';
import api from '@/services/api';

import MessageItem from '@/components/message/MessageItem.vue';

export default {
  name: 'LiveFeed',
  components: {
    MessageItem,
  },
  setup() {
    const feedObj = reactive({
      feedData: [],
      subCategoryName: {},
      nextCriteria: {},
      currentCriteria: {},
    });
    const isInit = ref(false);
    const isLoading = ref(true);
    const hasMore = ref(true);
    let interOb = null;
    let eofObj = null;
    let loadingMore = false;
    let feedInterval = null;

    // get Data
    const currentFeedTime = ref(dayjs().format('H:mm:ss a'));
    const onNextPage = async () => {
      console.log('load next page');

      // From last element find from created_at -1
      if (!loadingMore) {
        loadingMore = true;
        const args = JSON.parse(JSON.stringify(feedObj.currentCriteria));
        let currentData = feedObj.feedData;
        const currentLength = currentData.length;
        const lastItem = currentData[currentLength - 1];
        args.time = {
          untilDate: lastItem.info.created_at - 1,
        };
        const result = await api.getOlderLatestFeed(args).catch(() => {});
        if (result && result.message) {
          feedObj.currentCriteria = result.message.criteria;
          // Concat data
          // const data = await dispatch('config/highlightKeywords', {
          //   messageList: result.message.data,
          //   truncateAt: 0,
          // });
          const data = result.message.data;
          const newData = currentData.concat(data);
          feedObj.feedData = newData;
          if (data.length > 0) {
            hasMore.value = true;
          } else {
            hasMore.value = false;
          }
        }
        loadingMore = false;
      }
    };
    const getFeedData = async () => {
      isLoading.value = true;
      const result = await api
        .getLatestFeed({
          sentiment: ['positive', 'neutral'],
          sort: {
            field: 'created_at',
            direction: 'desc',
          },
          originOnly: true,
        })
        .catch(() => {});
      if (result && result.message) {
        // console.log(result.message);
        feedObj.subCategoryName = result.message.subCategoryName;
        feedObj.feedData = result.message.data;
        feedObj.nextCriteria = result.message.nextCriteria;
        feedObj.currentCriteria = result.message.currentCriteria;
        if (!interOb) {
          // One time binding for loading more
          interOb = new IntersectionObserver(
            (entries) => {
              console.log(entries);

              if (!entries[0].isIntersecting) {
                // Do nothing on leave
              } else {
                // On freakin enter
                if (isInit.value && !isLoading.value) {
                  onNextPage();
                }
              }
              // On change
            },
            {
              threshold: 1.0,
              // root: document.getElementById('app'),
              root: document.getElementById('inner-app'),
              rootMargin: '0px',
            }
          );
          setTimeout(() => {
            const elem = document.querySelector('.eof');
            if (elem) {
              interOb.observe(elem);
            }
          }, 2000);
        }
        // console.log(feedObj);
        isInit.value = true;
        isLoading.value = false;
      }
    };
    const getNewerFeedData = async () => {
      let oldData = feedObj.feedData;
      const result = await api.getLatestFeed(feedObj.nextCriteria).catch(() => {});
      if (result && result.message) {
        const data = result.message.data;
        feedObj.nextCriteria = result.message.nextCriteria;
        currentFeedTime.value = dayjs().format('H:mm:ss a');
        feedObj.feedData = data.concat(oldData);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300);
      }
    };

    // Width track
    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      feedInterval = setInterval(() => {
        getNewerFeedData();
      }, 60000);
      getFeedData();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onWidthChange);
      clearInterval(feedInterval);
      if (eofObj) {
        // Do destroy;
        inView.clearHistory();
      }
    });
    const width = computed(() => windowWidth.value);

    return {
      isInit,
      isLoading,
      hasMore,
      feedObj,
      width,
      currentFeedTime,
    };
  },
};
</script>

<style lang="scss">
.live-feed {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .page-content {
    max-height: calc(100% - 40px);
    padding: 0 20px 20px 20px;
    overflow: auto;
  }
  .title-text {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #272b41;
    padding: 20px 20px 0 20px;
  }
  .time-text {
    font-size: 14px;
    color: #5a5f7d;
    padding: 15px 20px 15px 20px;
  }
  .message-card {
    background-color: #fff;
    border-radius: 10px;
    padding-top: 15px;
  }
  .masonry-wrapper {
    padding: 1.5em;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
  .masonry {
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-gap: 24px;
    grid-auto-rows: 8px;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1200px) {
    .masonry {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
  }
  @media only screen and (min-width: 1600px) {
    .masonry {
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }
  .masonry-item,
  .masonry-content {
    border-radius: 10px;
    overflow: hidden;
  }
  .message-card {
    .ant-card-body {
      // padding: 0 0 20px 0 !important;
    }
  }
  .eof {
    text-align: center;
    padding: 20px;
  }
}
#inner-app {
  max-height: 100vh;
  overflow-y: auto;
}
</style>
