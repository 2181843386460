<template>
  <div :id="`inner-${msgId}`" class="message-item">
    <MessageHead
      class="message-item-head"
      :username="username"
      :source="source"
      :orderNo="orderNo"
      :profileImage="profileImage"
      :date="dateStr"
    >
      <template v-slot:right>
        <div class="right-block">
          <SentimentCircleIcon :data="item" />
          <sdFeatherIcons v-if="editable" class="more-menu" type="more-horizontal" size="24" />
        </div>
      </template>
    </MessageHead>
    <div class="media-wrapper" v-if="hasMedia">
      <img :id="`img-${msgId}`" class="media-img" :src="imageUrl" />
    </div>
    <div class="content-wrapper">
      <div class="text-th message-title" v-if="raw && raw.title">
        {{ raw.title }}
      </div>
      <div class="text-th" v-if="raw && raw.hastag">
        <a-tag class="hashtag" v-for="tag in raw.hashtag" :key="tag" :color="currentSourceColor">
          {{ tag }}
        </a-tag>
      </div>
      <span class="text-th">
        {{ textContent }}
      </span>
      <a v-if="isLongText" class="seemore" @click="toggleFullText">
        <span v-if="showFullText">See Less</span>
        <span v-else>See More</span>
      </a>
      <div class="thumbnail-wrapper" v-if="hasThumb">
        <img :id="`thumb-${msgId}`" class="thumb-img" :src="imageUrl" />
      </div>
    </div>

    <EngagementBar class="engage-bar" :class="{ 'no-category': !showCategory }" :data="item" />
    <CategoriesBar
      v-if="showCategory"
      class="category-bar"
      :data="item"
      :editable="editable"
      :subCategoryName="subCategoryName"
    />
  </div>
</template>

<script>
import { toRefs, computed, ref, onMounted, watch } from 'vue';
import imagesLoaded from 'imagesloaded';

import MessageHead from './MessageHead.vue';
import EngagementBar from './EngagementBar.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';
import CategoriesBar from './CategoriesBar.vue';

import helper from '@/services/helper';

export default {
  components: {
    MessageHead,
    EngagementBar,
    CategoriesBar,
    SentimentCircleIcon,
  },
  props: {
    item: Object,
    isCount: Boolean,
    orderNo: Number,
    showCategory: Boolean,
    editable: Boolean,
    subCategoryName: Object,
    hasMedia: Boolean,
    hasThumb: Boolean,
    gridItem: Boolean,
    windowWidth: Number,
    updateVar: String,
  },
  setup(props) {
    const { item, isCount, gridItem, hasThumb } = toRefs(props);
    const showFullText = ref(false);
    const toggleFullText = () => {
      showFullText.value = !showFullText.value;
    };
    const msgId = computed(() => {
      if (item.value && item.value.info) {
        const { id } = item.value.info;
        return id;
      }
      return '';
    });
    const username = computed(() => {
      if (item.value && item.value.user) {
        const { username } = item.value.user;
        return username;
      }
      return 'User';
    });
    const source = computed(() => {
      if (item.value && item.value.info) {
        const { source } = item.value.info;
        return source;
      }
      return 'others';
    });
    const profileImage = computed(() => {
      if (item.value && item.value.user) {
        const { photo } = item.value.user;
        return photo;
      }
      return 'User';
    });
    const unitText = computed(() => {
      if (isCount.value) {
        return 'Mentions';
      }
      return 'Engagements';
    });
    const dateStr = computed(() => {
      if (item.value && item.value.info) {
        const { created_at } = item.value.info;
        return helper.formatDate(created_at, 'D MMM YYYY, H:mm A');
      }
      return '...';
    });
    const isLongText = computed(() => {
      if (item.value && item.value.raw_data) {
        const { text } = item.value.raw_data;
        if (text && text.length > 250) return true;
      }
      return false;
    });
    const textContent = computed(() => {
      if (item.value && item.value.raw_data) {
        const { text } = item.value.raw_data;
        if (text && text.length > 250) {
          if (showFullText.value) {
            return text;
          }
          return helper.truncate(text) + '...';
        } else {
          return text;
        }
      }
      return '...';
    });
    const imageUrl = computed(() => {
      if (item.value && item.value.raw_data) {
        const { image } = item.value.raw_data;
        return image;
      }
      return '';
    });
    const currentSourceColor = computed(() => {
      let color = '#2C99FF';
      if (item.value && item.value.info) {
        const { source } = item.value.info;
        if (source === 'facebook') color = '#1877F2';
        if (source === 'twitter') color = '#1DA1F2';
        if (source === 'pantip') color = '#7674BC';
        if (source === 'youtube') color = '#FF0000';
        if (source === 'instagram') color = '#E4405F';
        if (source === 'website') color = '#2C99FF';
      }
      return color;
    });

    const resizeItem = (msgId) => {
      setTimeout(() => {
        if (gridItem.value) {
          const inner = document.getElementById(`inner-${msgId}`);
          const msg = document.getElementById(`msg-${msgId}`);
          const grid = document.getElementsByClassName('masonry')[0];
          if (grid) {
            const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
            const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
            if (inner) {
              const bound = inner.getBoundingClientRect();
              const rowSpan = Math.ceil((bound.height + rowGap + 14) / (rowHeight + rowGap));
              msg.style.gridRowEnd = 'span ' + rowSpan;
            } else {
              console.log('INNER NOT FOUND', msgId);
            }
          }
        }
      });
    };

    watch(
      () => props.windowWidth,
      () => {
        if (msgId.value) {
          resizeItem(msgId.value);
        }
      }
    );
    watch(
      () => props.updateVar,
      () => {
        // console.log('OHH its time', msgId.value);
        setTimeout(() => {
          if (msgId.value) {
            resizeItem(msgId.value);
          }
        });
      }
    );

    onMounted(() => {
      if (msgId.value) {
        const elem = document.getElementById(`img-${msgId.value}`);
        if (elem) {
          imagesLoaded(elem, () => {
            // console.log('OH YEAH', elem);
            resizeItem(msgId.value);
          }).on('fail', () => {
            // console.log('FAILed');
            elem.remove();
            resizeItem(msgId.value);
          });
        } else {
          // calculate height without image
          resizeItem(msgId.value);
        }
      } else {
        // console.log('Naaaa');
      }
      if (hasThumb.value) {
        const elem = document.getElementById(`thumb-${msgId.value}`);
        if (elem) {
          imagesLoaded(elem, () => {}).on('fail', () => {
            elem.remove();
          });
        }
      }
    });

    return {
      username,
      source,
      profileImage,
      unitText,
      dateStr,
      textContent,
      isLongText,
      showFullText,
      toggleFullText,
      raw: item.raw_data,
      imageUrl,
      msgId,
      currentSourceColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  .right-block {
    display: flex;
    align-items: center;
    .more-menu {
      margin-left: 18px;
      color: #adb4d2;
    }
  }
  .message-item-head {
    padding: 0 20px;
  }
  .message-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #272b41;
  }
  .content-wrapper {
    padding: 0 20px 14px 20px;
    font-size: 15px;
    line-height: 24px;
    margin: 16px 0;
    color: #5a5f7d;
    border-bottom: 1px solid #f1f2f6;
    .hashtag-wrapper {
      margin-bottom: 18px;
    }
    .hashtag {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
  .engage-bar {
    padding: 0 20px;
    margin-bottom: 14px;
    &.no-category {
      margin-bottom: 36px;
    }
  }
  .category-bar {
    border-top: 1px solid #f1f2f6;
    padding: 20px;
  }
  .seemore {
    margin-left: 3px;
    color: #333;
    font-weight: 500;
  }
  .media-wrapper {
    border-top: 1px solid #f1f2f6;
    margin-top: 15px;
    .media-img {
      width: 100%;
      height: auto;
    }
  }
  .thumbnail-wrapper {
    margin-top: 16px;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    .thumb-img {
      width: 80px;
      height: 80px;
      background-color: #adb4d2;
    }
  }
}
</style>
