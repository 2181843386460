import dayjs from 'dayjs';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  namespaced: true,
  state: {
    brand: '',
    colors: {},
    userAccount: {},
    userInfo: {},
    summary: {},
    mode: '',
    totalUsage: 0,
  },
  getters: {
    colors: (state) => state.colors,
    hasUserInfo({ userInfo }) {
      if (userInfo && userInfo.id) {
        return true;
      }
      return false;
    },
    sourceNameList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources;
      }
      return [];
    },
    sourceList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources.map((s) => {
          return {
            value: s,
            text: helper.capitalize(s),
          };
        });
      }
      return [];
    },
    sortedSourceList({ userAccount }) {
      if (userAccount && userAccount.visibleSources) {
        let srcList = userAccount.visibleSources.sort();
        let finalSrc = [];
        const sList = ['facebook', 'instagram', 'pantip', 'twitter', 'youtube'];
        sList.forEach((s) => {
          if (srcList.includes(s)) {
            finalSrc.push(s);
          }
        });
        for (let src of srcList) {
          if (!sList.includes(src)) {
            finalSrc.push(src);
          }
        }
        return finalSrc;
      }
      return [];
    },
    accessRights({ userAccount }) {
      if (userAccount && userAccount.accessRights) {
        return userAccount.accessRights;
      }
      return [];
    },
    queryLength({ userAccount }) {
      if (userAccount && userAccount.queryLength) {
        return userAccount.queryLength;
      }
      return 28;
    },
    userRole({ userInfo }) {
      if (userInfo && userInfo.role) {
        return userInfo.role;
      }
      return 'user';
    },
    userRoleText({ userInfo }) {
      if (userInfo && userInfo.role) {
        switch (userInfo.role) {
          case 'admin':
            return 'Admin';
          case 'domadmin':
            return 'DOM Admin';
          case 'feedonly':
            return 'Inspector';
          default:
            return 'User';
        }
      }
      return 'User';
    },
    userName({ userInfo }) {
      if (userInfo && userInfo.username) {
        return userInfo.username;
      }
      return '';
    },
  },
  actions: {
    async logout({ commit }) {
      await api.logout();
      commit('clearConfig');
    },
    clearConfig({ commit }) {
      commit('clearConfig');
    },
    setConfig({ commit }, configData) {
      commit('setConfig', configData);
      commit('setColorConfig', configData);
    },
    async getUsageStats({ commit }) {
      const since = dayjs().startOf('month').valueOf();
      const until = dayjs().endOf('month').valueOf();
      const result = await api.getUsageStats(since, until).catch(() => {});
      if (result.message && result.message.total) {
        commit('setTotalUsage', result.message.total);
      }
    },
  },
  mutations: {
    clearConfig(state) {
      state.brand = '';
      state.colors = {};
      state.userAccount = {};
      state.userInfo = {};
      state.summary = {};
      state.mode = '';
    },
    setConfig(state, data) {
      state.brand = data.brand;
      state.userAccount = data.userAccount;
      state.userInfo = data.userInfo;
      state.summary = data.summary;
      state.mode = data.mode;
    },
    setColorConfig(state, data) {
      const result = {};
      data.colors.forEach((c) => {
        result[c.id] = c.color;
      });
      state.colors = result;
    },
    setTotalUsage(state, data) {
      state.totalUsage = data;
    },
  },
};
